import { registerApplication, start } from "single-spa";

// CONCILIATION APP
registerApplication({
  name: "@pedepronto/conciliation",
  app: () => System.import("@pedepronto/conciliation"),
  activeWhen: ["/"]
});

start({
  urlRerouteOnly: true,
});
